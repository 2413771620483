<template>
  <div v-if="showPage" id="divAddRemoveCardWrapper" class="template-wrapper">
    <!-- <iframe class="iframe_pos" id="my_iframe" name="my_iframe" src=""></iframe> -->
    <div class="template-wrapper-buttons" v-if="userCanAccessIyzico">
      <h1 class="cardInformation">Kredi Kartı</h1>
      <a
        href="javascript:void(0)"
        class="template-wrapper-buttons-add"
        @click="handleSubmit('addIyzicoCard')"
      >
        <VueText sizeLevel="7" weightLevel="2" color="grey-40">Ekle</VueText>
        <img src="@/assets/images/icons/add-circle.png" alt="icon" />
      </a>
      <a
        href="javascript:void(0)"
        class="template-wrapper-buttons-remove"
        @click="handleSubmit('addIyzicoCard')"
      >
        <VueText sizeLevel="7" weightLevel="2" color="grey-40">Çıkar</VueText>
        <img src="@/assets/images/icons/remove-circle.png" alt="icon" />
      </a>
    </div>
    <div class="template-wrapper-buttons" v-if="userCanAccessGaranti">
      <h2 class="cardInformation">Diğer Ticari Kartlar</h2>
      <a
        href="javascript:void(0)"
        class="template-wrapper-buttons-add"
        @click="handleSubmit('addCard')"
      >
        <VueText sizeLevel="7" weightLevel="2" color="grey-40">Ekle</VueText>
        <img src="@/assets/images/icons/add-circle.png" alt="icon" />
      </a>
      <a
        href="javascript:void(0)"
        class="template-wrapper-buttons-remove"
        @click="handleSubmit('removeCard')"
      >
        <VueText sizeLevel="7" weightLevel="2" color="grey-40">Çıkar</VueText>
        <img src="@/assets/images/icons/remove-circle.png" alt="icon" />
      </a>
    </div>
    <div class="template-wrapper-add"></div>
    <div class="template-wrapper-remove"></div>
  </div>
</template>
<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Finance } from '@/services/Api/index';

export default {
  name: 'AddRemoveCreditCard',
  components: {
    VueText,
  },
  data() {
    return {
      showPage: true,
      addUrl: '',
      removeUrl: '',
      iyzicoCardPageUrl: '',
      userCanAccessIyzico: '',
      userCanAccessGaranti: '',
      addFormData: {
        secure3dsecuritylevel: '',
        mode: '',
        identity: '',
        orderid: '',
        cardrelationid: '',
        apiversion: '',
        terminalprovuserid: '',
        terminaluserid: '',
        terminalid: '',
        terminalmerchantid: '',
        customeripaddress: '',
        customeremailaddress: '',
        txntype: '',
        txnamount: '',
        txncurrencycode: '',
        companyname: '',
        txninstallmentcount: '',
        successurl: '',
        errorurl: '',
        secure3dhash: '',
        txnmotoind: '',
        mobilind: '',
        txntimestamp: '',
        lang: '',
        refreshtime: '',
        commercialcardduedate: '',
        commercialcardamount: '',
      },
      removeFormData: {
        secure3dsecuritylevel: '',
        mode: '',
        cardnumber: '',
        cardexpiredatemonth: '',
        cardexpiredateyear: '',
        Cardcard_ref: '',
        identity: '',
        txnsubtype: '',
        cardcvv2: '',
        orderid: '',
        cardrelationid: '',
        apiversion: '',
        terminalprovuserid: '',
        terminaluserid: '',
        terminalid: '',
        terminalmerchantid: '',
        customeripaddress: '',
        customeremailaddress: '',
        txntype: '',
        txnamount: '',
        txncurrencycode: '',
        companyname: '',
        txninstallmentcount: '',
        successurl: '',
        errorurl: '',
        secure3dhash: '',
        txnmotoind: '',
        mobilind: '',
        txntimestamp: '',
        lang: '',
      },
    };
  },
  destroyed() {
    let posFrame = document.getElementById('pos_iframe');
    if (posFrame) posFrame.remove();
  },
  created() {
    window.document.addEventListener('posReturn', this.posReturn, false);
    // let posFrame = document.getElementById('pos_iframe');
    let message = '';
    let status = '';
    let process = '';
    if (this.$route.query && this.$route.query.success) {
      status = 'success';
      if (this.$route.query.success === 'add') {
        message = 'Kart Ekleme işlemi başarı ile gerçekleşti';
        process = 'addcard';
      } else if (this.$route.query.success === 'remove') {
        message = 'Kart Çıkarma işlemi başarı ile gerçekleşti';
        process = 'removecard';
      }
      this.dispatchResult({ status, message, process });
    } else if (this.$route.query && this.$route.query.error) {
      status = 'error';
      if (this.$route.query.error === 'add') {
        message = 'Kart Ekleme işlemi sırasında hata gerçekleşti';
        process = 'addcard';
      } else if (this.$route.query.error === 'remove') {
        message = 'Kart Çıkarma işlemi sırasında hata gerçekleşti';
        process = 'removecard';
      }
      this.dispatchResult({ status, message, process });
    }

    Finance.getBosPermission().then(res => {
      const {
        Data: { userCanAccessIyzico, userCanAccessGaranti },
      } = res.data;

      this.userCanAccessIyzico = userCanAccessIyzico;
      this.userCanAccessGaranti = userCanAccessGaranti;
    });

    Finance.getIyzicoCardPageUrl().then(res => {
      const {
        Data: { cardPageUrl },
      } = res.data;

      this.iyzicoCardPageUrl = cardPageUrl;
    });

    Finance.getCreditCardModels().then(res => {
      const {
        Data: { addCardParams, removeCardParams },
      } = res.data;

      this.addFormData.secure3dsecuritylevel = addCardParams.secure3dSecurityLevel;
      this.addFormData.mode = addCardParams.mode;
      this.addFormData.identity = addCardParams.identity;
      this.addFormData.orderid = addCardParams.cardOrderID;
      this.addFormData.cardrelationid = addCardParams.cardReationID;
      this.addFormData.apiversion = addCardParams.apiVersion;
      this.addFormData.terminalprovuserid = addCardParams.terminalProvUserID;
      this.addFormData.terminaluserid = addCardParams.terminalUserID;
      this.addFormData.terminalid = addCardParams.terminalID;
      this.addFormData.terminalmerchantid = addCardParams.terminalMerchantID;
      this.addFormData.customeripaddress = addCardParams.customerIpAdress;
      this.addFormData.customeremailaddress = addCardParams.customerEmailAddress;
      this.addFormData.txntype = addCardParams.txnType;
      this.addFormData.txnamount = addCardParams.txnAmount;
      this.addFormData.txncurrencycode = addCardParams.txnCurrencyCode;
      this.addFormData.companyname = addCardParams.companyName;
      this.addFormData.txninstallmentcount = addCardParams.txnInstallmentCount;
      this.addFormData.successurl = addCardParams.successUrl;
      this.addFormData.errorurl = addCardParams.errorUrl;
      this.addFormData.secure3dhash = addCardParams.secure3DHash;
      this.addFormData.txnmotoind = addCardParams.txnmotoind;
      this.addFormData.mobilind = addCardParams.mobilind;
      this.addFormData.txntimestamp = addCardParams.txnTimestamp;
      this.addFormData.lang = addCardParams.lang;
      this.addFormData.refreshtime = addCardParams.refreshTime;
      this.addFormData.commercialcardduedate = '';
      this.addFormData.commercialcardamount = '';

      this.addUrl = addCardParams.url;

      this.removeFormData.secure3dsecuritylevel = removeCardParams.secure3dSecurityLevel;
      this.removeFormData.mode = removeCardParams.mode;
      this.removeFormData.cardnumber = removeCardParams.cardNumber;
      this.removeFormData.cardexpiredatemonth = removeCardParams.cardExpireMonth;
      this.removeFormData.cardexpiredateyear = removeCardParams.cardExpireYear;
      this.removeFormData.Cardcard_ref = removeCardParams.cardCard_ref;
      this.removeFormData.identity = removeCardParams.identity;
      this.removeFormData.txnsubtype = removeCardParams.txnSubType;
      this.removeFormData.cardcvv2 = removeCardParams.cardCvv2;
      this.removeFormData.orderid = removeCardParams.orderID;
      this.removeFormData.cardrelationid = removeCardParams.cardRelationID;
      this.removeFormData.apiversion = removeCardParams.apiVersion;
      this.removeFormData.terminalprovuserid = removeCardParams.terminalproviderUserID;
      this.removeFormData.terminaluserid = removeCardParams.terminalUserID;
      this.removeFormData.terminalid = removeCardParams.terminalID;
      this.removeFormData.terminalmerchantid = removeCardParams.terminalMerchantID;
      this.removeFormData.customeripaddress = removeCardParams.customerIpAdress;
      this.removeFormData.customeremailaddress = removeCardParams.customerEmailAdress;
      this.removeFormData.txntype = removeCardParams.txnType;
      this.removeFormData.txnamount = removeCardParams.txnAmount;
      this.removeFormData.txncurrencycode = removeCardParams.txnCurrencyCode;
      this.removeFormData.companyname = removeCardParams.companyName;
      this.removeFormData.txninstallmentcount = removeCardParams.txnInstallmentCount;
      this.removeFormData.successurl = removeCardParams.successUrl;
      this.removeFormData.errorurl = removeCardParams.errorUrl;

      this.removeFormData.secure3dhash = removeCardParams.secure3dHash;
      this.removeFormData.txnmotoind = removeCardParams.txnMotoinD;
      this.removeFormData.mobilind = removeCardParams.mobilind;
      this.removeFormData.txntimestamp = removeCardParams.txtTimeStamp;
      this.removeFormData.lang = removeCardParams.lang;

      this.removeUrl = removeCardParams.url;
    });
  },
  mounted() {
    if (!window.frameElement || window.frameElement.id !== 'pos_iframe') {
      this.showPage = true;
    }
  },
  methods: {
    //method triggered from iframe
    posReturn(e) {
      if (e.detail.status === 'error') {
        console.error(e.detail.message, e.detail);
      }
      let posFrame = document.getElementById('pos_iframe');
      if (posFrame) posFrame.remove();
      this.$store.dispatch('app/setBackFunction', null);
      this.$store.dispatch('app/setBottomBarStatus', true);
      this.$store.dispatch('app/setHeadTitle', null);
    },
    //dispatching the event to parent containing the iframe
    dispatchResult(eventData) {
      if (window.frameElement) {
        if (window.frameElement.id === 'pos_iframe') {
          window.parent.document.dispatchEvent(new CustomEvent('posReturn', { detail: eventData }));
        }
      }
    },
    post(path, target, params, method = 'POST') {
      //The rest of this code assumes you are not using a library.
      // It can be made less wordy if you use one.
      const form = document.createElement('form');
      form.method = method;
      form.action = path;
      form.target = target;

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];

          form.appendChild(hiddenField);
        }
      }
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    get(path, target, params, method = 'GET') {
      const form = document.createElement('form');
      form.method = method;
      form.action = path;
      // form.target = target;

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    handleSubmit(formId) {
      var frame = document.createElement('iframe');
      frame.setAttribute('name', 'pos_iframe');
      frame.setAttribute('id', 'pos_iframe');
      frame.frameBorder = 0;
      frame.setAttribute(
        'style',
        'position:absolute;height:calc(100% - 70px);width:100%;top:70px;bottom:0;left:0;right:0;',
      );
      this.$store.dispatch('app/setHeadTitle', formId == 'addCard' ? 'Kart Ekle' : 'Kart Çıkar');
      this.$store.dispatch('app/setBackFunction', () => {
        let posFrame = document.getElementById('pos_iframe');
        if (posFrame) posFrame.remove();
        this.$store.dispatch('app/setBackFunction', null);
        this.$store.dispatch('app/setBottomBarStatus', true);
        this.$store.dispatch('app/setHeadTitle', null);
      });
      this.$store.dispatch('app/setBottomBarStatus', false);
      document.body.appendChild(frame);

      if (formId == 'addCard') this.post(this.addUrl, 'pos_iframe', this.addFormData);
      else if (formId == 'removeCard') this.post(this.removeUrl, 'pos_iframe', this.removeFormData);
      else if (formId == 'addIyzicoCard') this.get(this.iyzicoCardPageUrl, 'pos_iframe', null);
    },
  },
};
</script>
<style lang="scss" scoped>
.template-wrapper {
  height: 100%;
  .iframe_pos {
    height: 100%;
    width: 100%;
  }
  &-buttons {
    padding: 0 25px;
    a {
      border-bottom: 1px solid palette-color-level('grey', '20');
      padding: 25px 0;
      display: flex;
      text-decoration: none;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-add {
  }
  &-remove {
  }

  .cardInformation {
    font-size: 25px;
    font-weight: bold;
    border-bottom: 0.5px solid palette-color-level('grey', '20');
    padding: 25px 0;
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
}
</style>
